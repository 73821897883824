import React, { Component } from "react"
import { Link } from "gatsby"

class ProductSideBar extends Component {
  render() {
    const { product } = this.props

    return (
      <div>
        <span>
          <Link key={product.slug + "1"} to={`/${product.slug}`}>
            <img
              src={product.thumnail.file.url}
              alt="productimage"
              className="img-rounded"
            />
          </Link>
          <Link key={product.slug + "a"} to={`/${product.slug}`}>
            <span>{product.name}</span>
            {/* <span className="meta">{blog.blogPublishDate}</span> */}
          </Link>
        </span>
      </div>
    )
  }
}

export default ProductSideBar
