import React, { Component } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import BlogPreviewComponent from "../components/BlogPreviewComponent"
import { graphql } from "gatsby"
import ProductPreview from "../components/ProductPreview"
import Img from "gatsby-image"
import ProductSideBar from "../components/ProductSideBar"

export class ProductIndex extends Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const product = this.props.data.allContentfulProduct.edges
    const singleProduct = this.props.data.contentfulProduct
    const Imageurl = this.props.data.contentfulPortfolio

    let SliderImage
    if (Imageurl) {
      SliderImage = (
        <Img
          fluid={singleProduct.thumnail.fluid}
          backgroundColor={"#f4f8fb"}
          loading="eager"
          fadeIn={false}
        />
      )
    }

    return (
      <div>
        <Helmet title={siteTitle} />
        <Layout>
          <Helmet title={`${singleProduct.name}`} />

          <div className="inner-blog-post pad-40">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="entry-media">
                    {/* <Img
                    fluid={singlepost.blogImage.fluid}
                    backgroundColor={"#f4f8fb"}
                  /> */}
                    <Img
                      fluid={singleProduct.thumnail.fluid}
                      backgroundColor={"#f4f8fb"}
                      loading="eager"
                      fadeIn={false}
                    />
                  </div>
                  <div className="post-content">
                    <h2 className="section-headline"> {singleProduct.name} </h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          singleProduct.description.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 offset-md-1 ">
                  <div className="sidebar-blk">
                    <ul className="">
                      {product.map(({ node }, index) => {
                        return (
                          <li key={index} id={index + "1"}>
                            <ProductSideBar product={node} />
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <Share socialConfig={{config: { socialConfigss, }, }} /> */}

              {/* <Share
              socialConfig={{
                ...socialConfigss.site.siteMetadata.twiteerhandletitle,
                config: {
                  url: `${siteurl}${socialConfigss.slug}`,
                  title: `${socialConfigss.title}`,
                },
              }}
            />
            <DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            /> */}
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default ProductIndex

export const pageQuery = graphql`
  query ProductIndexQuery($slug: String) {
    site {
      siteMetadata {
        title
        description
      }
    }

    allContentfulProduct {
      edges {
        node {
          name
          description {
            description
          }
          slug
          thumnail {
            file {
              url
            }
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }

    contentfulProduct(slug: { eq: $slug }) {
      name
      slug

      description {
        childMarkdownRemark {
          html
        }
      }
      thumnail {
        file {
          url
        }
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      thumnail {
        file {
          url
        }
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
